@use '../../theme' as *;
#sketchBoard{
    background-color: $light;
    @include rounded($radius-3, "b");
    @include rounded($radius-3, "l");
}

#FreeHand{
    background-color: $white;
    width: 200vw;
    height: 200vw;
    position: relative;
    // transition: $transition-0-1 ease-out;
    background-blend-mode: 
        normal;
        @include rounded($radius-2, "xy");
    &.active{
        background: 
        // linear-gradient( /* background color fading left to right , to blend with every others gradient bg */
        //   to left, 
        //   #8382a2, 
        //   #2f4e79),
        radial-gradient( /* dot repeated via background-size */
            circle at 5px 5px,
            colorRange($primary-1, 10) 1px,
            transparent 0px,
            transparent 0px
          )
          -0.15% -0.15% / 0.5% 0.5% /*position + /  background-size here */;
          background-position: -0.15 -0.15;
          background-color: $white;
    }
}
#contextMenu{
    position: absolute;
    transition: $transition-0-1 ease-out;
    .context-items{
        // padding: 10px 0px  10px 0px;
        font-size: 10px;
        &:hover{
            background-color: $light;
            cursor: pointer;
        }
        >i{
            border-right: $lightborder-1;
            padding: 12px 12px  12px 12px;
        }
        >div{
            padding: 10px 8px;
            .shortKyes{
                font-size: 10px;
                font-weight: bold;
            }
        }
    }
}
.userNonSelect {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}
.FreeComponent {
    position: absolute;
    transition: $transition;
    .selectArea{
        border: 2px dotted rgba(0, 0, 0, 0);
    }
    &.editMode{
        cursor: pointer;
        &:hover {
            >.ribbon {
                display: flex;
            }
    
            >.stretch- {
    
                &move,
                &width,
                &height,
                &auto-height,
                &size {
                    display: inline-block;
                }
            }
            .selectArea{
                border: 2px dotted colorRange($black, 1);
            }
        }
    }


    &.active {

        >.ribbon {
            background-color: $warning-0;
            display: flex;
            color: white;
        }

        >.stretch- {

            &move{
                display: inline-block !important;
                color: $warning-1;
            }
            &width,
            &height,
            &auto-height,
            &size {
                display: inline-block !important;
                background-color: $warning-0;
            }
        }
        .selectArea{
            border: 2px dotted $warning-0 !important;
        }
        // &:hover {
        //     .selectArea{
        //         border: 2px solid $warning-0;
        //     }
        // }
    }

    >.ribbon {
        position: absolute;
        top: 0;
        right: -2px;
        transform: translateY(-100%);
        background-color: $light;
        padding: 5px 10px;
        display: none;
        font-size: 12px;
        z-index: 1;
        @include rounded($radius-2, "t");
        @include rounded($radius-2, "r");

        i {
            padding: 0 10px;

            &:hover {
                color: $danger-0;
            }
        }
        >div{
            margin: -5px -10px -5px 0;
        }
    }

    .stretch- {

        &move,
        &width,
        &height,
        &auto-height, 
        &size {
            position: absolute;
            z-index: 1;
            padding: 0;
            line-height: 15px;
            font-size: 10px;
            width: 15px;
            color: $white;
            border: 0px;
            background-color: $dark;
            @include rounded($radius-1, "xy");
            display: none;
        }

        &move {
            top: -0.15px;
            left: -0.3px;
            cursor: move;
            transform: translate(-55%, -55%);
            background-color: transparent;
            color: $warning-1;
            font-size: 15px;
        }
        &width {
            top: 50%;
            right: 0%;
            cursor: e-resize;
            transform: translate(50%, -50%);
        }

        &height {
            left: 50%;
            bottom: 0%;
            cursor: n-resize;
            transform: translate(-50%, 50%);
        }

        &auto-height {
            left: calc(50% - 10px);
            bottom: 0%;
            cursor: pointer;
            width: auto;
            padding: 0 5px  ;
            transform: translate(-100%, 50%);
        }

        &size {
            bottom: 0%;
            right: 0%;
            cursor: nw-resize;
            transform: translate(50%, 50%) rotate(-45deg);
        }
    }

    &.dragEffect {
        @include animation("dragEffect 3s infinite");

        @include keyframes("dragEffect") {

            0%,
            100% {
                border: 2px solid $primary-1;
            }
            
            50% {
                border: $lightborder-2;
            }
        }
    }

    &.replaceEffect {
        @include animation("replaceEffect 1s infinite");

        @include keyframes("replaceEffect") {

            0%,
            100% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(10px);
            }
        }
    }
}
.dark-theme{
    #sketchBoard{
        background-color: $black;
    }
    
    #FreeHand{
        background-color: $black;
    }
}