@use "../../theme" as *;

.myInput {
    position: relative;

    .myInputContainer {
        // background-color: green;
        // overflow: hidden;
        display: flex;

        &:hover fieldset {
            border-color: rgb(75, 177, 255);
        }

        &:hover legend {
            color: rgb(75, 177, 255);
        }


        >fieldset {
            border-width: 1px;
            border-color: rgb(27, 117, 188);
            border-style: solid;
            position: absolute;
            top: -10px;
            left: 0;
            right: 0;
            bottom: 0px;
            // z-index: ;
            @include rounded($radius-4, "xy");

            >legend {
                font-size: 80%;
                width: auto;
                color: rgb(27, 117, 188);
                margin-left: 10px;
                padding: 0 5px;
            }
        }

        >input,
        >textarea {
            position: relative;
            z-index: 1;
            padding: 8px 15px;
            border: 0;
            outline-width: 0;
            background-color: transparent;
            width: 100%;
        }

        /* Chrome, Safari, Edge, Opera */
        .inpNumber::-webkit-outer-spin-button,
        .inpNumber::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        .inpNumber {
            -moz-appearance: textfield;
        }

        >button {
            z-index: 1;
        }

        .danger {
            border-color: rgba(255, 0, 0, 1) !important;
            color: rgba(255, 0, 0, 1) !important;
        }
    }
}
.dark-theme{
    .myInput {
        .myInputContainer {
            >fieldset {
                border-color: $primary-dark-1;
                >legend {
                    color: $primary-dark-1;
                }
            }
            &:hover fieldset {
                border-color: $white;
            }
            &:hover legend {
                color: $white;
            }
            >input {
                color: $white;
            }
            &-list {
                .list-container {
                    >.item {
                        &:hover {
                            background-color: $grey;
                        }
                    }
                }
            }
        }
    }
}