@use "../../../theme" as *;

.userdropdown {
    display: flex;
    justify-content: space-between;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba($color: #9e9e9e, $alpha: 0.1);
    background-color: transparent;
    width: 185px;
    font-size: 12px;
    text-align: left;
    padding: 8px 15px;

    &:hover {
        background-color: $light;
    }
}

.seprater {
    @include split("t");
}