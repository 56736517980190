@use '../../../theme' as *;

/* Function & Variable */
.graph-guage {
  background-color: rgb(200, 200, 200);
  position: relative;
  padding-top: 50%;
  width: 100%;
  margin-bottom: 30px;
  border-top-left-radius: 10000px;
  border-top-right-radius: 10000px;
}

.graph-guage>.mask {
  background-color: white;
  position: absolute;
  top: 20%;
  left: 10%;
  width: 80%;
  bottom: -2px;
  border-top-left-radius: 10000px;
  border-top-right-radius: 10000px;
  transition: all 0.3s;
}

.graph-guage>.mask>.guageArrow {
  height: 80%;
  position: absolute;
  bottom: -4%;
  left: 50%;
  transition: all 0.3s;
  transform: translateX(-50%) rotate(-90deg);
  transform-origin: 50% 94.1%;
}

.graph-guage>.mask>.label {
  position: absolute;
  top: 105%;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  font-size: 100%;
}

/*# sourceMappingURL=guage.css.map */


.graph-range {
  margin: 10px 0;
}

.graph-range-label {
  margin: 20px 0 10px 0;
}

.graph-range,
.graph-range-label {
  @include rounded($radius, "xy");
  background-color: rgb(200, 200, 200);
  width: 100%;
  height: 10px;
  position: relative;

  >.label {
    position: absolute;
    bottom: 120%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 60%;
    font-weight: bold;
  }

  >.indicator {
    position: absolute;
    bottom: 100%;
    left: 0%;
    transition: $transition;
    transform: translateX(-50%);
  }

  >.text-value {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 50%;
    font-weight: bold;
    text-align: center;
  }
}