@use "../../theme" as *;

.tabButton {
    @include rounded($radius-4, "b");
    @include rounded($radius-4, "l");
    @include linear-gradient("silver", false);
    min-width: 50px;
    text-align: center;
    border: 0px;
    margin-right: 3px;
    padding: 0px;
    cursor: pointer;

    &.active {
        @include dropshadow-1;
        color: $primary-1;

        .close-btn {
            display: inline;
        }
    }

    &.danger {
        box-shadow: unset;
        color: red
    }

    >.inner-btn {
        border: 0;
        background: transparent;
        padding: 10px 15px;
    }

    >.close-btn {
        display: none;
    }
}