@use '../../../theme' as *;

.propertiesLabels {
    @include split("b");
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    color: $dark;
}

.setAlignStr0 {
    stroke: black;
    stroke-width: 0.65;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 2.61313;
}

.setAlign0 {
    fill: none;
}

.setAlign1 {
    fill: black;
}

.setAlign2 {
    fill: #999999;
    cursor: pointer;

    &:hover {
        stroke: #999999;
        stroke-width: 5;
    }

    &.active {
        stroke: black;
        stroke-width: 10;
    }
}

.selectiveColors {
    width: 30px;
    height: 30px;
    @include rounded($radius-1, "xy");
    cursor: pointer;

    &:hover {
        @include xyProps("border", "xy", "2px solid " + $light);
    }
}