.machineContainer {
    position: absolute;
    bottom: 10%;
    transform: translateX(0);
}

.slideIn {
    animation: slideInMachine 0.5s;
}

.slideOut {
    animation: slideOutMachine 0.5s;
}

.slideUpIn {
    animation: slideUp 0.5s;
}

.slideDownOut {
    animation: slideDown 0.5s reverse;
}

.slideAlternate {
    animation: slideOutMachine 0.5s alternate 2;
}

.zoomAlternate {
    animation: zoomAlternate 0.2s alternate 2;
}

@keyframes slideInMachine {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideOutMachine {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }

    100% {
        transform: translateX(-100px);
        opacity: 0;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes zoomAlternate {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
    }
}

.productImg {
    height: 150px;
}

.brakerImg {
    height: 100px;
    transform: rotateY(180deg);
    margin-right: 30px;
}

.tags {
    position: absolute;
    /* transform: translate(-50%,-50%); */
    border-radius: 20px;
    /* border: 2px solid black; */
    padding: 5px 7px;
    background-color: white;
    color: green;
    box-shadow: 0px 10px 10px -2px rgba(126, 252, 0, 0.3);
    cursor: pointer;
}




.str0 {
    stroke: #B3B3B3;
    stroke-width: 1000px
}

.str1 {
    stroke: black;
    stroke-width: 1000px
}

.fil17 {
    fill: none
}

.fil13 {
    fill: black
}

.fil5 {
    fill: #000066
}

.fil4 {
    fill: #003399
}

.fil31 {
    fill: #0344A6
}

.fil32 {
    fill: #038C03
}

.fil25 {
    fill: #1A1A1A
}

.fil2 {
    fill: #1D3B59
}

.fil11 {
    fill: #333333
}

.fil29 {
    fill: #4D4D4D
}

.fil30 {
    fill: #6489AD
}

.fil12 {
    fill: #666666
}

.fil1 {
    fill: gray
}

.fil20 {
    fill: gray
}

.fil8 {
    fill: #999999
}

.fil15 {
    fill: #AAA69D
}

.fil3 {
    fill: #B3B3B3
}

.fil10 {
    fill: #CCCCCC
}

.fil14 {
    fill: #CCCCFF
}

.fil27 {
    fill: #CCCCFF
}

.fil33 {
    fill: #D40000
}

.fil19 {
    fill: #D40000
}

.fil16 {
    fill: #E6E6E6
}

.fil6 {
    fill: #FF9933
}

.fil18 {
    fill: #FFCC00
}

.fil0 {
    fill: white
}

.fil21 {
    fill: black;
    fill-rule: nonzero
}

.fil9 {
    fill: #6666FF;
    fill-rule: nonzero
}

.fil23 {
    fill: black;
    fill-opacity: 0.101961
}

.fil7 {
    fill: black;
    fill-opacity: 0.301961
}

.fil28 {
    fill: #CCCCFF;
    fill-opacity: 0.301961
}

.fil24 {
    fill: black;
    fill-opacity: 0.501961
}

.fil22 {
    fill: black;
    fill-opacity: 0.800000
}

.fil26 {
    fill: url(#id0)
}

#packMachine .tooltipText {
    opacity: 0;
    transition: all 0.5s;
}

/* #packMachine:hover .tooltipText{
      opacity: 1;
    } */

.animationTime {
    animation-duration: 1s;
}

.animationState {
    animation-play-state: paused;
}

.animationVisibility {
    visibility: hidden;
}

.wheelAnimation {
    animation-name: rotateAnimate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rotateAnimate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.boxOpen {
    transform: rotate(-73deg) skewY(-48deg) scale(0.5, 0.1);
    transform-origin: 16.9% 30%;
    animation-name: skewRotateAnimate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes skewRotateAnimate {
    0% {
        transform: rotate(-73deg) skewY(-48deg) scale(0.5, 0.1);
    }

    100% {
        transform: rotate(0deg) skewY(0deg) scale(1);
    }
}

.boxMovement {
    animation-name: translateAnimate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes translateAnimate {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(4.6%);
    }
}

.boxMovement2 {
    animation-name: translateAnimate2;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes translateAnimate2 {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(1%);
    }
}

.box90 {
    animation-name: clock90;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

@keyframes clock90 {
    0% {
        transform: translateX(0%) rotate(0deg);
    }

    50% {
        transform: translateX(0%) rotate(90deg);
    }

    100% {
        transform: translateX(5%) rotate(90deg);
    }
}

.lightBlink {
    animation-name: lightScale;
    animation-iteration-count: infinite;
}

@keyframes lightScale {

    0%,
    100% {
        transform: scale(1);
        filter: brightness(1);
    }

    50% {
        transform: scale(1.5);
        filter: brightness(1.5);
    }
}

.blink {
    animation-name: lightOnOf;
    animation-iteration-count: infinite;
}

@keyframes lightOnOf {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.blind {
    animation-name: bright;
    animation-iteration-count: infinite;
}

@keyframes bright {

    0%,
    70%,
    85%,
    100% {
        filter: brightness(1);
    }

    80%,
    90%,
    99% {
        filter: brightness(3);
    }
}

.printSpray {
    animation-name: spray;
    animation-iteration-count: infinite;
}

@keyframes spray {

    0%,
    70%,
    85%,
    100% {
        opacity: 0;
    }

    80%,
    90%,
    99% {
        opacity: 1;
    }
}

.machineBack_2 {
    fill: none
}

.machineBack_8 {
    fill: black;
    transition: all 1s;
}

.machineBack_1 {
    fill: #B3B3B3
}

.machineBack_4 {
    fill: #CC3300
}

.machineBack_9 {
    fill: #FF9900
}

.machineBack_10 {
    fill: #FFCC00
}

.machineBack_0 {
    fill: white;
    transition: all 1s;
}

.machineBack_7 {
    fill: white;
    fill-rule: nonzero
}

.machineBack_3 {
    fill: url(#machineBack7);
    /* filter: drop-shadow( 0px 10px 10px rgba(0, 0, 0, 0.3)); */
    transition: all 1s;
}

/* .machineBack_3.active {filter: drop-shadow( 0px 0px 60px #00ccffd7);} */
.machineBack_32 {
    fill: url(#machineBack7_2);
    /* filter: drop-shadow( 0px 0px 60px #00ccffd7); */
    transition: all 1s;
    opacity: 0;
}

.machineBack_6 {
    fill: url(#machineBack8)
}

.machineBack_5 {
    fill: url(#machineBack9)
}

.machineBack_62 {
    fill: url(#machineBack8_2);
    transition: all 1s;
    opacity: 0;
}

.machineBack_62.active {
    opacity: 1;
}

#GE {
    transform: translate(17%, -25%)
}

/* #GE .progressRingInner{
      stroke: #0471C4;
    }
    #GE .progressPer,#GE .progressText{
      fill: #0471C4;
    } */
#OEE {
    transform: translate(29%, -25%)
}

/* #OEE .progressRingInner{
      stroke: #00C441;
    }
    #OEE .progressPer,#OEE .progressText{
      fill: #00C441;
    } */
#LU {
    transform: translate(41%, -25%)
}

/* #LU .progressRingInner{
      stroke: #FF6600;
    }
    #LU .progressPer,#LU .progressText{
      fill: #FF6600;
    } */
#TargetCount {
    transform: translate(-30%, -22%)
}

#nightMode,
#nextBtn,
#playBtn,
#pauseBtn,
#backBtn,
#fullScrBtn {
    cursor: pointer;
    transition: all 0.1s
}

#fullScrBtn {
    transform: translate(4.3%, 2.3%);
    transform-origin: 78% 5%;
}

#nightMode {
    transform-origin: 82% 5%;
}

#backBtn {
    transform-origin: 85.5% 5%;
}

#playBtn {
    transform-origin: 88% 5%;
}

#pauseBtn {
    transform-origin: 88% 5%;
    opacity: 0;
    transition: opacity 1s;
}

#nextBtn {
    transform-origin: 91% 5%;
}

#nightMode:hover,
#pauseBtn:hover,
#backBtn:hover,
#nextBtn:hover,
#playBtn:hover {
    transform: scale(1.1);
}

#fullScrBtn:hover {
    transform: scale(1.1) translate(4.3%, 2.3%);
}

#nightMode:active,
#pauseBtn:active,
#backBtn:active,
#nextBtn:active,
#playBtn:active {
    transform: scale(0.9);
}

#fullScrBtn:active {
    transform: scale(0.9) translate(4.3%, 2.3%);
}

#fullScrBtn>.machineBack_8 {
    transition: all 1s;
}

#fullScrBtn>.machineBack_8.active {
    d: path("M2772.79 69.24c1.36,0 2.47,-1.11 2.47,-2.47 0,-1.36 -1.11,-2.47 -2.47,-2.47l-9.9 -0c-1.36,0 -2.47,1.11 -2.47,2.47l0 9.9c0,1.36 1.11,2.47 2.47,2.47 1.36,0 2.47,-1.11 2.47,-2.47l0 -7.42 7.42 -0zm-22.08 7.42c0,1.36 1.11,2.47 2.47,2.47 1.36,0 2.47,-1.11 2.47,-2.47l0 -9.89c-0,-1.36 -1.11,-2.47 -2.47,-2.47l-9.9 0c-1.36,0 -2.47,1.11 -2.47,2.47 0,1.36 1.11,2.47 2.47,2.47l7.42 0 0 7.42zm-7.52 -22.3c-1.36,0 -2.47,1.11 -2.47,2.47 -0,1.36 1.11,2.47 2.47,2.47l9.9 0c1.36,0 2.47,-1.11 2.47,-2.47l0 -9.9c0,-1.36 -1.11,-2.47 -2.47,-2.47 -1.36,0 -2.47,1.11 -2.47,2.47l0 7.42 -7.42 0zm22.18 -7.31c0,-1.36 -1.11,-2.47 -2.47,-2.47 -1.36,0 -2.47,1.11 -2.47,2.47l-0 9.89c0,1.36 1.11,2.47 2.47,2.47l9.9 0c1.36,0 2.47,-1.11 2.47,-2.47 0,-1.36 -1.11,-2.47 -2.47,-2.47l-7.42 0 -0 -7.42z");
}

#nightMode>.night,
#nightMode>.day,
#nightMode>.btn {
    transition: all 0.5s ease-out;
}

.nightMode_active>.btn {
    transform: translateX(2%) !important;
}

.nightMode_active>.day {
    fill: white !important;
}

.nightMode_active>.night {
    fill: black !important;
}

.playsStatus {
    font-family: arial;
    font-size: 250%;
    fill: white;
    transform-origin: 95% 5%;
}

.machineName {
    font-family: arial;
    font-size: 350%;
    font-weight: bold;
    fill: white;
}

.skuName {
    font-family: arial;
    font-size: 350%;
    fill: #1A71B5;
}

.skuDate1 {
    font-family: arial;
    font-size: 200%;
    fill: black;
}

.targetValue,
.produceValue {
    font-family: arial;
    font-weight: bold;
    font-size: 500%;
    fill: red;
}

.targetTxt,
.produceTxt {
    font-family: arial;
    font-size: 200%;
    font-weight: bold;
    fill: #1A71B5;
}

.standard1,
.lastupd1 {
    font-family: arial;
    font-size: 250%;
    font-weight: bold;
    fill: white;
}

.standard2,
.standard3 {
    font-family: arial;
    font-size: 400%;
    font-weight: bold;
    fill: white;
}

.lastupd2 {
    font-family: arial;
    font-size: 200%;
    fill: white;
}

text {
    transition: all 1s;
}

.active1 {
    /* filter: drop-shadow( 0px 0px 60px #00ccffd7); */
    opacity: 1;
}

.active2 .progressRingInner {
    stroke: url(#machineBack7_2);
    /* filter: drop-shadow( 0px 0px 30px #00FFFF); */
}

.active2 .progressRing {
    stroke: #e6e6e620;
}

.active2 .progressPer,
.active2 .progressText,
.active2.skuName,
.active2.targetTxt,
.active2.produceTxt {
    fill: url(#machineBack7_2);
    /* filter: drop-shadow( 0px 0px 15px #00FFFF); */
}

.active3.targetValue,
.active3.produceValue,
.active3.machineBack_4 {
    fill: red;
    /* filter: drop-shadow( 0px 0px 15px red); */
}

.active4.skuDate1 {
    fill: white;
    /* filter: drop-shadow( 0px 0px 15px white); */
}

.active0.machineBack_0 {
    fill: black;
}

.active0.machineBack_0 {
    fill: black;
}

.active0.machineBack_8 {
    fill: white;
}