.tank_fil1 {fill:#DBB240}
.tank_fil3 {fill:#aa7e048f}
.tank_fil6 {fill:#00000000; transform: scaleX(1.2); transform-origin: center;}     /* //for drop */
.tank_fil2 {fill:#dbb23f}
.tank_fil7 {fill:black}
.tank_fil5 {fill:#6b4f0100;}
.tank_fil4 {fill:white;fill-opacity:0.3}
.tank_fil0 {fill:url('#id0')}
.tank_fnt0 {font-weight:bold;font-size:10000.08px;font-family:'Arial'; transform-origin: center; fill: white;}
.wave1{
  transform: translateY(45%);
  animation: waveOne 4s linear infinite;
}
.wave2{
  transform: translateY(45%);
  animation: waveTwo 5s linear infinite;
}
@keyframes waveOne{
                        0%, 100% { d: path('M2656 63635c20021,-12355 30087,7721 73597,0l0 6888 -73597 0 0 -6888z'); }
      50%{d:path('M2656 63635c29165,7996 56772,-11067 73597,0l0 6888 -73597 0 0 -6888z'); }
}
@keyframes waveTwo{
                        0%, 100% { d: path('M76253 63635c-20021,-12355 -30087,7721 -73597,0l0 6888 73597 0 0 -6888z'); }
      50%{d:path('M76253 63635c-16658,5386 -62307,-9054 -73597,0l0 6888 73597 0 0 -6888z'); }
}
.tank_wave{
  transform: translateY(0%);
}