@use '../../../theme' as *;

.scaleOnMouse{
    @include rounded($radius-4, "xy");
    border: 2px solid colorRange($dark, 1);
    .scaleRange {
        display: inline-block;
        color: black;
        &.l {
            border-left: 2px solid rgb(97, 84, 84);
        }
        &.r {
            border-right: 2px solid rgb(97, 84, 84);
            transform: translateX(-100%);
        }
    }
}