.signal_strength{
  fill: lightgrey;
  &.active{
    &:nth-child(1){
      fill: hsl(0,70,50);
    }
    &:nth-child(2){
      fill: hsl(25,70,50);
    }
    &:nth-child(3){
      fill: hsl(50,70,50);
    }
    &:nth-child(4){
      fill: hsl(75,70,50);
    }
    &:nth-child(5){
      fill: hsl(100,70,50);
    }
  }
  &:nth-child(6){
    fill: hsl(0,70,50);
    stroke: white;
    stroke-width: 2px;
    stroke-opacity: 1;

    transform: rotate(-45deg) translate(-7%,20%);
  }
  &.offline{
    fill: grey;
  }
}