@use "../../theme" as *;


.deviceCard{
    transition: $transition;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 5px 0 5px;
    @include media-query($xxl) {
        width: 200px;
    }
    .deviceCardinner{
        margin: 0 10px 20px 10px;
        display: flex;
        justify-content: center;
        width: 300px;
        @include media-query($xxl) {
            width: 200px;
        }
        .devCard{
            width: 100%;
            margin-top: 70px;
            @include media-query($xxl) {
                margin-top: 30px;
            }
            .deviceArea{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                top: 0;
                min-height: 400px;
                @include media-query($xxl) {
                    min-height: 280px;
                }
                .deviceImage{
                    width: 120px;
                    height: 120px;
                    margin-top: -70px;
                    @include media-query($xxl) {
                        width: 80px;
                        height: 80px;
                        margin-top: -40px;
                    }
                }
            }
        }
    }
    .deviceRoundBtn {
        @include rounded($radius-px, "xy");
        padding: 0;
        height: 35px;
        width: 35px;
        font-size: 16px;
        @include media-query($xxl) {
            height: 25px;
            width: 25px;
            font-size: 12px;
        }
    }
    .expandBtn{
        font-size: 16px;
        padding: 0;
        height: 30px;
        width: 100%;
        @include rounded($radius-4, "b");
        @include rounded($radius-4, "l");
        @include media-query($xxl) {
            @include rounded($radius-px, "b");
            @include rounded($radius-px, "l");
            height: 20px;
            font-size: 12px;
        }
    }
    .deviceDetail{
        font-size: 16px;
        @include media-query($xxl) {
            font-size: 14px;
        }
        td:nth-child(1){
            max-width: 130px;
            width: 130px;
            min-width: 130px;
            text-align: right;
            @include media-query($xxl) {
                max-width: 90px;
                width: 90px;
                min-width: 90px;
            }
        }
        th{
            min-width: 130px;
            width: 130px;
            @include media-query($xxl) {
                min-width: 90px;
                width: 90px;
            }
        }
    }
    .deviceTitle{
        font-size: 18px;
        font-weight: bolder;
        @include media-query($xxl) {
            font-size: 14px;
        }
    }
    .deviceUpdate{
        font-size: 14px;
        color: grey;
        @include media-query($xxl) {
            font-size: 10px;
        }
    }
}

.deviceBtn {
    background-color: $white;
    border: 0;
}

.deviceBtn:hover {
    background-color: $light;
}
.dark-theme{
    .deviceBtn{
        background-color: transparent;
        color: $white;
    }
    .deviceBtn:hover{
        background-color: $dark;
    }
}