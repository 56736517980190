@use '../../../theme' as *;

.linearProgress {
    width: 100%;

    .progress {
        background-color: #e6e6e6;
        height: 10px;
        @include rounded($radius-5, "xy");
        width: 100%;
        overflow: hidden;

        .innerProgress {
            @include rounded($radius-5, "xy");
            height: 100%;
            transition: $transition-1;
        }
    }

}