@use '../../../theme' as *;


.gen_str0 {stroke:#4D4D4D;stroke-width:21.37;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.61313}
.gen_str1 {stroke:black;stroke-width:7.12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.61313}
.gen_fil6 {fill:none}
.gen_fil4 {fill:#030B0E}
.gen_fil3 {fill:#030F1D}
.gen_fil10 {fill:#050304}
.gen_fil7 {fill:#050304}
.gen_fil9 {fill:#12171A}
.gen_fil13 {fill:#151A1D}
.gen_fil5 {fill:#202B2D}
.gen_fil8 {fill:#4D4D4D}
.gen_fil2 {fill:#828282}
.gen_fil1 {fill:#C7C8C3}
.gen_fil12 {fill:#ED5770}
.gen_fil11 {fill:#FFF048}
.gen_fil0 {fill:#131F1F;fill-rule:nonzero}