@use "../../theme" as *;

// Layout styling

// .fa-bell {
//     font-size: 30px;
// }

.bell-notification::after {
    content: attr(current-count);
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    top: -10px;
    right: -10px;
}


main {
    margin-left: 50px;
    padding-top: 50px;
    min-height: calc(100vh - 50px);

    // height: 100px;
    @include media-query($md) {
        margin-left: 0px;
    }
}

.footer {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    img {
        height: 30px;

        @include media-query($sm) {
            height: 20px;
        }
    }

    @include media-query($sm) {
        font-size: 12px;
    }
}



// DefaultHeader

.topContainer {
    @include dropshadow-1;
    // @include linear-gradient("silver", false);
    background-position: 0% 180%;
    background-size: 100% 500%;
    background-image: 
    radial-gradient(
      circle at 80% 0%,
      rgba(27, 118, 187, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 80% 100%,
      rgba(27, 118, 187, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 90% 40%,
      rgba(255, 144, 107, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 25% 70%,
      rgba(27, 118, 187, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 15% 30%,
      rgba(241, 90, 39, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 30% 0%,
      rgba(241, 90, 39, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 30% 100%,
      rgba(241, 90, 39, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 20% 5%,
      rgba(27, 118, 187, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 30% 50%,
      rgba(241, 90, 39, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 75% 50%,
      rgba(27, 118, 187, 0.2),
      transparent 5%
    ),
    radial-gradient(
      circle at 75% 18%,
      rgba(241, 90, 39, 0.2),
      transparent 5%
    ),
    linear-gradient(45deg,colorRange($white, 15),colorRange($white, 10)),
    ;
    animation: bubblesMove 20s;
    position: fixed;
    display: flex;
    justify-content: space-between;
    height: 50px;
    top: 5px;
    left: 5px;
    right: 5px;
    z-index: 1000;
    @include rounded($radius-3, "xy");
    
    >.blurContainer {
        position: absolute;
        @include blur($blur2);
        height: 50px;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        @include rounded($radius-3, "xy");
    }
    
    .mainSearchContainer {
        align-self: center;
        flex: 1;
        max-width: 700px;
        margin: 0 20px;
        padding: 5px;
        background-color: $white;
        @include rounded($radius-2, "xy");
        @include dropshadow(false, 0px, 0px, 10px, colorRange($black, 1));
        
        @include media-query($md) {
            margin: 0 10px;
        }

        .mainSearch {
            background-color: transparent;
            border: 1px solid $light;
            padding: 5px 10px;
            width: 100%;
            font-size: 14px;
            @include rounded($radius-2, "xy");
            @include dropshadow(false, 0px, 0px, 0px, colorRange($primary-0, 1));
            transition: $transition-1;
            &:focus{
                @include dropshadow(false, 0px, 0px, 10px, colorRange($primary-1, 20));
                outline: 1px solid $primary-1;
            }
        }

        .searchItems {
            @include dropshadow-1;
            background-color: $white;
            padding: 5px 10px;
            margin-top: 5px;
            display: block;
            color: $dark;
            @include rounded($radius-1, "xy");

            &:hover {
                background-color: $light;
            }
        }
    }

    a {
        text-decoration: none;

        img {
            height: 40px;
            margin: 5px 0 0 60px;

            @include media-query($md) {
                height: 30px;
                margin: 5px 0 0 60px;
            }
        }
    }

    .titleName {
        line-height: 90px;
        font-size: 50px;
        text-align: center;
        flex: 1;
    }

    .userdropdown {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        @include split("b");
        background-color: transparent;
        width: 185px;
        font-size: 12px;
        text-align: left;
        padding: 8px 15px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: $light;
        }
    }
}
@keyframes bubblesMove {
    0%{background-position: 0% 0%;}
    100%{background-position: 0% 180%;}
}
.menubtn {
    @include rounded($radius-2, "xy");
    background-color: colorRange($primary-1, 20);
    @include linear-gradient("primary", false);
    position: fixed;
    text-align: center;
    top: 7.25px;
    left: 10px;
    padding: 5px 0 0 0;
    z-index: 10000;
    cursor: pointer;
    width: 45px;
    height: 45px;

    // @include media-query($md) {
    //     background-color: transparent;
    // }

    i {
        color: $white;
        transition: $transition-0-5;

        @include media-query($md) {
            color: $black;
        }
    }

    &.open {
        @include media-query($md) {
            background-color: colorRange($black, 12);
        }

        i {
            @include media-query($md) {
                color: $white;
            }
        }
    }
}

.bell-notification {
    position: relative
}

.user-icon {
    font-size: 14px;
    align-self: center;
    line-height: 20px;
    outline: 0;

    &:focus {
        box-shadow: none;
    }

    @include media-query($md) {
        align-self: center;
        line-height: 25px;
        font-size: 14px;
    }
}

.secondaryLogo {
    height: 30px;
    align-self: center;
    margin-right: 10px;
}



// Sidebar styling
#sideContainer {
    @include dropshadow-1;
    @include blur($blur2);
    @include rounded($radius-3, "xy");
    background-position: 300% 300%;
    background-size: 500% 500%;
    background-image: 
        radial-gradient(
        circle at 80% 0%,
        rgba(27, 118, 187, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 80% 100%,
        rgba(27, 118, 187, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 90% 40%,
        rgba(255, 144, 107, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 25% 70%,
        rgba(27, 118, 187, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 15% 30%,
        rgba(241, 90, 39, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 30% 0%,
        rgba(241, 90, 39, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 30% 100%,
        rgba(241, 90, 39, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 20% 5%,
        rgba(27, 118, 187, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 30% 50%,
        rgba(241, 90, 39, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 75% 50%,
        rgba(27, 118, 187, 0.2),
        transparent 5%
        ),
        radial-gradient(
        circle at 75% 18%,
        rgba(241, 90, 39, 0.2),
        transparent 5%
        ),
        linear-gradient(45deg,colorRange($white, 15),colorRange($white, 10)),
    ;
    animation: bubblesMove1 20s;
    position: fixed;
    z-index: 1020;
    top: 60px;
    left: 5px;
    min-height: calc(100% - 65px);
    // overflow: hidden;
    transition: $transition-0-5;
    width: 50px;
    padding: 5px;

    @include media-query($md) {
        width: 0px;
        padding: 0px;
    }

    .sideSearch {
        background-color: transparent;
        border: 1px solid grey;
        padding: 0px 10px;
        width: 100%;
        font-size: 14px;
        @include rounded($radius-3, "xy")
    }

    .navIcon {
        padding: 0 30px 0 5px !important;

        @include media-query($md) {
            padding: 0 30px 0 5px;
        }
    }

    .navbtn {
        font-size: 14px !important;
        font-family: Arial, Helvetica, sans-serif !important;
        color: $black;
        list-style-type: none;
        display: block;
        align-items: center;
        // justify-content: space-between;
        padding: 10px 10px 10px 7.5px;
        margin-bottom: 4px;
        transition: $transition-0-5;
        width: 40px;
        overflow: hidden;
        border-radius: 15px;
        cursor: pointer;

        @include media-query($md) {
            font-size: 14px;
            padding: 0px;
            width: 0px;
        }

        i,
        span {
            color: $black;
            transition: $transition-0-5;
        }

        &:hover,
        &.active {
            @include dropshadow-1;
            transition: $transition;
            @include linear-gradient("primary", false);
            @include blur($blur5);
            color: $white !important;
            text-decoration: none;

        }

        &:hover i,
        &:hover span,
        &.active i,
        &.active span {
            color: $white;
            @include rounded($radius-2, "xy");
        }

        &:hover {
            width: 230px;
        }

    }

    &.open {
        width: 250px;

        @include media-query($md) {
            width: calc(100% - 10px);
            padding: 5px;
        }

        .navbtn {
            width: 240px;
            @include media-query($md) {
                width: 100%;
                padding: 10px;
            }
        }
    }

    a {
        text-decoration: none !important;

        img {
            margin: 5px 0 5px 60px;
            height: 40px;
            filter: brightness(100);

            @include media-query($md) {
                height: 30px;
            }
        }
    }
}
@keyframes bubblesMove1 {
    0%{background-position: 0% 0%;}
    100%{background-position: 300% 300%;}
}

.sub-navbtn {
    border-radius: 15px;

    a {
        font-size: 14px;
        padding: 5px 10px;
        color: $dark;
        width: 100%;

        &:hover {
            background-color: $light;

        }
    }
}

// Sidebar End

.dark-theme {
    .topContainer {
        @include linear-gradient("dark", false);
        animation: none;
        .mainSearchContainer {
            background-color: colorRange($primary-1, 5);
            .mainSearch {
                color: $white;
                border: 1px solid $white;
                &::placeholder{
                    color: $primary-1;
                }
            }

            .searchItems {
                background-color: $dark;
                color: $white;

                &:hover {
                    background-color: $primary-0;
                }
            }
        }
    }

    .user-icon {
        color: $white;
    }

    #sideContainer {
        background-position: center;
        background-size: 100%;
        @include gradient("linear", "45", colorRange($dark, 10), colorRange($dark, 18));
        animation: none
    }
    .userdropdown {
        @include splitDark("b");
        color: $white;

        &:hover {
            background-color: $dark;
        }
    }

    .sideSearch {
        color: white;
    }

    .navbtn {
        color: $white;

        i,
        span {
            color: $white !important;
        }
    }

    .sub-navbtn {
        color: $light;

        &:hover {
            color: $primary-1;

        }
    }
}