@use "../../theme" as *;
.drawing-area {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
  }
  
  polyline {
    fill: none;
    stroke: $primary-1;
    stroke-width: 2;
  }
  