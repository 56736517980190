.progressRing {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: #e6e6e6;
    stroke-width: .3em;
    transition: all 1s;
}

.progressRingInner {
    transform: rotate(-90deg);
    transform-origin: center;


    stroke-width: .3em;
    stroke-dashoffset: 950;
    transition: all 1s;
    transition-delay: 0.1s;

}

.progressPer {
    // fill: #0471C4;
    font-family: arial;
    font-size: 100%;
}

.progressText {
    // fill: #0471C4;
    font-family: arial;
    font-size: 50%;
}