.MotorStr1 {stroke:black;stroke-width:0.93;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.61313}
    .MotorStr0 {stroke:red;stroke-width:3.74;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.61313;stroke-dasharray:3.736032 18.680160}
    .motorAnime18 {fill:none}
    .motorAnime7 {fill:#0F1C33}
    .motorAnime9 {fill:#1C2742}
    .motorAnime14 {fill:#434E65}
    .motorAnime6 {fill:#4C5B74}
    .motorAnime13 {fill:#5671B5}
    .motorAnime2 {fill:#5870B5}
    .motorAnime12 {fill:#5970B5}
    .motorAnime16 {fill:#5972B6}
    .motorAnime1 {fill:#6688C6}
    .motorAnime8 {fill:#668AC7}
    .motorAnime11 {fill:#6CA5DA}
    .motorAnime0 {fill:#6CAADD}
    .motorAnime15 {fill:#6EA9DD}
    .motorAnime17 {fill:#9BA9CC}
    .motorAnime5 {fill:#B1B6D0}
    .motorAnime4 {fill:#CACCE1}
    .motorAnime3 {fill:#E2E6F1}
    .motorAnime10 {fill:#F3F2F8}
    .motorAnime22 {fill:#0D3D62;fill-rule:nonzero}
    .motorAnime19 {fill:#1C2742;fill-rule:nonzero}
    .motorAnime20 {fill:#5870B5;fill-rule:nonzero}
    .motorAnime21 {fill:#6CAADD;fill-rule:nonzero}
    .rodeRotate1{
        transform: rotateX(-35deg) rotateY(48deg);
        transform-origin: 54% 72.9%;
    }
    .rodeRotate{
        transform: translate(0%,-23%) rotateZ(0deg);
        transform-origin: 55.24% 93.95%;
        animation: rodeRotation 0.25s infinite linear;
    }
    @keyframes rodeRotation{
        0%{transform: translate(0%,-23%) rotateZ(0deg);}
        100%{transform: translate(0%,-23%) rotateZ(360deg);}
    }
    .rodeCut{
        transform: translate(49.8%,69.3%) rotateZ(30deg) skew(30deg, -1deg);
        animation: rodeCuts 0.125s infinite linear;
    }
    @keyframes rodeCuts{
        0%{transform: translate(49.8%,69.3%) rotateZ(30deg) skew(30deg, -1deg);}
        50%{transform: translate(50%,65.1%) rotateZ(30deg) skew(0deg, 0deg);}
        100%{transform: translate(52.2%,63%) rotateZ(30deg) skew(-30deg, 1deg);}
    }
    .motorVibrate{
        animation: motorVibration 0.1s infinite linear;
    }
    @keyframes motorVibration{
        0%{transform: translate(-0.1%,0%);}
        30%{transform: translate(0%,0.1%);}
        70%{transform: translate(0.1%,0%);}
        100%{transform: translate(0%,-0.1%);}
    }
    .textSet{
        font-family: arial;
        font-size: 400%;
    }