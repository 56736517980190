@use "../../theme" as *;

.myCard,
.myCard-blur {
    @include dropshadow-1;
    @include rounded($radius-4, "xy");
    @include xyProps("border", "xy", "" + $lightborder-2);
    // @include linear-gradient("silver",false);

    // overflow: hidden;
    .header {
        padding: 10px 20px 0px 20px;
    }

    .spleater {
        @include split("b");
        margin: 10px 20px -1px 20px;
    }

    .heading-1 {
        font-size: 20px;
        font-weight: bold;
        font-family: Arial;
        color: $dark;
    }

    .heading-2 {
        font-size: 14px;
        font-weight: bold;
        font-family: Arial;
        color: $dark;
    }

    .innerCard {
        background-color: $white;
        height: 100%;
        @include dropshadow-1;
        @include rounded($radius-2, "xy");
    }

    .cardBody {
        .collapse {
            height: 0px;
        }
    }
}

.myCard-blur {
    @include blur($blur2);
}
.dark-theme {
    .myCard,
    .myCard-blur {
        @include xyProps("border", "xy", "0px");
        .header {
            padding: 10px 20px 10px 20px;
        }
        .spleater {
            @include xyProps("border", "xy", "0px");
            margin: 0px;

        }
        .heading-1 {
            color: $light;
        }
        .heading-2 {
            color: $light;
        }
    
        .innerCard {
            background-color: colorRange($black,10);
        }
    }
}