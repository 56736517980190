@use "../../theme" as *;

.mySelect {
    // overflow: hidden;
    display: flex;

    &:hover fieldset {
        border-color: rgb(75, 177, 255);
    }

    &:hover legend {
        color: rgb(75, 177, 255);
    }

    >fieldset {
        border-width: 1px;
        border-color: rgb(27, 117, 188);
        border-style: solid;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 0px;
        z-index: 0;
        @include rounded($radius-px, "xy");

        >legend {
            font-size: 80%;
            width: auto;
            color: rgb(27, 117, 188);
            margin-left: 10px;
            padding: 0 5px;
        }
    }

    >input {
        position: relative;
        z-index: 0;
        padding: 8px 15px;
        border: 0;
        outline-width: 0;
        background-color: transparent;
        width: 100%;
    }

    &-list {
        width: 100%;
        z-index: 100;

        .list-container {
            max-height: 300px;
            overflow-y: auto;

            >.item {
                padding: 5px 20px;
                cursor: default;

                &:hover {
                    background-color: $light;
                }

                label {
                    margin: 0;
                    width: 100%;

                    input {
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                    }

                    >button {
                        z-index: 1;
                    }

                }
            }

        }

    }

    .danger {
        border-color: rgba(255, 0, 0, 1) !important;
        color: rgba(255, 0, 0, 1) !important;
    }
}
.dark-theme{
    .mySelect {
        >fieldset {
            border-color: $primary-dark-1;
            >legend {
                color: $primary-dark-1;
            }
        }
        &:hover fieldset {
            border-color: $white;
        }
        &:hover legend {
            color: $white;
        }
        >input {
            color: $white;
        }
        &-list {
            .list-container {
                >.item {
                    &:hover {
                        background-color: $grey;
                    }
                }
            }
        }
    }
}