@use '../../../theme' as *;

.gm-style {
    .gm-style-mtc {
        &:first-child {
            margin-right: 10px;
        }

        >button,
        >ul {
            border-radius: 15px !important;
        }

        >ul {
            margin-top: 5px !important;

            >li {
                border-radius: 50px !important;

                >label {
                    margin: 0;
                }
            }
        }
    }

    .gm-fullscreen-control,
    .gm-svpc,
    .gmnoprint>div {
        border-radius: 15px !important;
    }
}

.gm-fullscreen-control {
    display: none !important;
}

.gm-style-mtc-bbw {
    display: none !important;
}

.infoBox>img {
    display: none !important;
}

.mapHistoryBar {
    @include linear-gradient("silver", false);
    @include rounded($radius-3, "xy");
    @include blur($blur1);
    padding: 10px;
    position: absolute;
    bottom: 25px;
    left: 10px;
    right: 60px;

    >div {
        gap: 10px;
        display: flex;

        >button {
            @include rounded($radius-2, "xy");
            color: $primary-1;
        }
    }
}