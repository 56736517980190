.machine_ATMstr0 {stroke:black;stroke-width:6.71;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256}
    .machine_ATMfil6 {fill:black}
    .machine_ATMfil3 {fill:#03A9F6}
    .machine_ATMfil5 {fill:#1A1A1A}
    .machine_ATMfil1 {fill:#313FA0}
    .machine_ATMfil13 {fill:#33f10d; animation: blink 1s infinite;}
    .machine_ATMfil20 {fill:#313FA0; font-size:400px;}
    @keyframes blink {
        0%,100%{opacity: 0;}
        50%{opacity: 1;}
    }
    .machine_ATMfil2 {fill:#3F51B5}
    .machine_ATMfil14 {fill:#9D4635}
    .machine_ATMfil10 {fill:#ADADAD}
    .machine_ATMfil11 {fill:#AFE7F6}
    .machine_ATMfil7 {fill:#BDBDBD}
    .machine_ATMfil9 {fill:#D1D1D1}
    .machine_ATMfil0 {fill:#E0E0E0}
    .machine_ATMfil12 {fill:#E7DE55}
    .machine_ATMfil8 {fill:#E8FEFC}
    .machine_ATMfil4 {fill:white}
    .machine_ATMfnt0 {font-weight:normal;font-size:1047.47px;font-family:'Arial'}