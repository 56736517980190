@use '../../theme' as *;

* {
    box-sizing: border-box;
}

.tabs {
    width: 100%;
    padding-top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    >.content {
        font-size: 100px;
        color: $primary-1;
        position: absolute;
        // background-color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    >.status{
        position: absolute;
        bottom: 15px;
        left: 15px;
        color: white;
        padding: 0 7px;
        @include rounded($radius-2, "xy");
        display: flex;
        align-items: center;
        gap: 5px;
    }
}
.overviewListContainer{
    opacity: 0;
    transform: translateY(50%);
    animation-name: component-animation;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1.5);
    animation-fill-mode: forwards;
    &.animation-end {
        opacity: 1;
        transform: translateX(0%);
    }
}
.overviewList {
    border: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
    &:hover {
        border: 2px solid colorRange($black,1);
        border-top-right-radius: 0;

        >.ribbon {
            border: 2px solid colorRange($black,1);
            display: flex;
        }
    }

    &.active {
        border: 2px solid $warning-0;
        border-top-right-radius: 0;

        >.ribbon {
            background-color: $warning-0;
            display: flex;
            color: white;
        }
    }

    >.ribbon {
        position: absolute;
        top: 0;
        right: -2px;
        transform: translateY(-100%);
        background-color: $white;
        padding: 5px 10px;
        display: none;
        font-size: 12px;
        @include rounded($radius-2, "t");
        @include rounded($radius-2, "r");

        i {
            padding: 0 10px;

            &.fa-cog:hover {
                color: $primary-1;
            }
            &.fa-trash-alt:hover {
                color: $danger-0;
            }
            &.fa-clone:hover {
                color: $success-0;
            }
        }
    }
}
@keyframes component-animation {
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

.dark-theme{
    .tabs {
        >.content {
            color: $primary-dark-0;
        }
    }
    .overviewList {
        &:hover {
            border: 2px solid $dark;
        }
        >.ribbon {
            background-color: $dark;
        }
    }
}