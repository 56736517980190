@use "../../theme" as *;

.myTable{
    overflow-y: auto;
    position: relative;
    >table{
        width: 100%;
        >thead{
            position: sticky;
            top: 0;
            @include dropshadow-1;
            background-color: $white;
            @include media-query($lg){
                display: table-header-group;
            }
            @include media-query($md){
                display: none;
            }
        }
        >tbody{
            >tr{
                @include split("b");
                cursor: default;
                &:hover{
                    background-color: $light;
                }
                >td{
                    >.row{
                        >.label{
                            font-weight: bold;
                                display: none;
                            @include media-query($md){
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    }
}