
/* Compass css */
.compass_fil0 {fill:black}
.compass_fil8 {fill:blue}
.compass_fil7 {fill:rgba(0,0,0,0.4)}
.compass_fil5 {fill:#BF9902}
.compass_fil9 {fill:red}
.compass_fil2 {fill:yellow}
.compass_fil6 {fill:white}
.compass_fil4 {fill:blue;fill-rule:nonzero}
.compass_fil1 {fill:#1A1A1A;fill-rule:nonzero}
.compass_fil3 {fill:red;fill-rule:nonzero}
.compass_fil10 {fill:white;fill-opacity:0.400000}
.compass_fil11 {fill:black;fill-opacity:0.501961}

.compass_vibrate2, .compass_vibrate_shadow2{
  /* transform: rotate(90deg);   */
  transition: all 2s;
  }

.compass_vibrate, .compass_vibrate_shadow{
  transform: rotate(-69deg);
  animation: vibrate 0.3s infinite;
}

.compass_vibrate{
  transform-origin: 50.8% 49.4%;
}
.compass_vibrate_shadow{
  transform-origin: 52% 52%;
}

.compass_vibrate_shadow2{
  transform-origin: 52% 52%;
}
.compass_vibrate2{
  transform-origin: 50.8% 49.4%;
}

@keyframes vibrate{
  0%,100%{transform: rotate(-68.5deg);}
  50%{transform: rotate(-69.5deg);}
}
.compass_text{
  font-size: 7000px;
}
/*  
body:hover .compass_vibrate2, body:hover .compass_vibrate_shadow2{
    transform: rotate(0deg);
    transition: all 0.3s;
  }
*/