@use "../../theme" as *;

.dropdown-container{
    position: relative;
    // z-index: ;
    .dropdown-body{
        position: absolute;
        top: 120%;
        // margin-top: 20%;
        // padding: 10px 0;
        overflow: hidden;
        z-index: 2;
        // @include linear-gradient("silver",false);
        @include xyProps("border","xy",$lightborder-2);
        @include dropshadow-1;
        @include blur($blur2);
        &.left{
            left: 5px;
            @include rounded($radius-4,"x");
            @include rounded($radius-4,"b");
            @include rounded($radius-1,"t");
        }
        &.right{
            right: 5px;
            @include rounded($radius-4,"y");
            @include rounded($radius-4,"l");
            @include rounded($radius-1,"r");
        }
        button{
            outline: 0 !important;
        }
    }
}

.dark-theme {
    .dropdown-container{
        .dropdown-body{
            // @include linear-gradient("dark",false);
            @include xyProps("border","xy","2px solid " + $dark);
        }
    }
}