@use '../../theme' as *;
.componentBarTabs{
    padding: 0px 0px 0px 20px !important;
    position: absolute;
    right: 100%;
    top: 0;
    margin: 0;
    transform: rotate(90deg) translate(100%, 50%);
    transform-origin: 100%;
    .componentBarTabsInner{
        display: flex;
        overflow: hidden;
        padding: 3px;
        background-color: $light;
        // @include rounded($radius-3, "xy");
        @include rounded(0, "t");
        @include rounded(0, "r");
        @include rounded($radius-2, "b");
        @include rounded($radius-2, "l");
        .vertTabButton {
            display: flex;
            @include rounded($radius-2, "xy");
            font-size: 14px;
            // font-weight: bold;
            >.inner-btn {
                border: 0;
                background: transparent;
                padding: 3px 10px 3px 10px;
                line-height: 16px
            }
            &.active{
                @include rounded(0, "t");
                @include rounded(0, "r");
                @include rounded($radius-1, "b");
                @include rounded($radius-1, "l");
                background-color: $white;
                margin-top: -3px;
                z-index: 1;
                >.inner-btn {
                    padding: 6px 10px 3px 10px;
                }
            }
        
            &-warning {
                color: $danger-1;
                // min-width: 50px;
                text-align: center;
                cursor: pointer;
                i{
                    font-size: 12px;
                    padding: 0 10px 0 0;
                    margin: 7px 0 -7px 0 ;
                }
                &.active {
                    @include linear-gradient("warning", false);
                    color: $white;
                    i{
                        font-size: 12px;
                        padding: 0 10px 0 0;
                        margin: 10px 0 -10px 0 ;
                    }
                }
            
                &.danger {
                    box-shadow: unset;
                    color: red
                }
            }
        }
    }
}
.component-list{
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    .component-item{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        border: $lightborder-2;
        @include rounded($radius-2,"xy");
        cursor: grab;
        &:active{
            cursor: grabbing;
        }
        &:hover{
            border: 2px solid $primary-1;
            >i{
                color: $primary-1;
            }
        }
        >*{
            text-align: center;
            font-size: 12px;
        }
        >i{
            padding: 30px 0 10px 0;
            font-size: 40px;
            color: $primary-0;
        }
    }
}

* {
    box-sizing: border-box;
}

.main {
    background-color: rgb(240, 240, 240);
    height: calc(80vh - 10px);
    display: flex;
    padding: 5px;
}

.sketchPanel {
    background-color: white;
    flex: 1;
    margin: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    /* resize: both; */
}

.sketchPanel>*:not(.placeholder) {
    user-select: none;
}

.sketchPanel>*:not(.placeholder):hover {
    background-color: rgb(240, 240, 240);
    outline: 1px red solid;
}

.sketchPanel>.placeholder {
    color: lightgrey;
    padding: 10px;
}

.toolPanel {
    width: 300px;
    margin: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.toolPanel>h5 {
    margin: 0px;
    padding: 10px;
    position: sticky;
    top: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    background-color: white;
}

.toolPanel>.items {
    margin: 0px;
    padding: 10px;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: white;
    cursor: grab;
    &:active {
        cursor: grabbing;
        background-color: rgb(250, 250, 250);
    }
    &:hover {
        >i,>img{
            transform: scale(1.05);
        }
    }
    >i,>img{
        transition: $transition;
    }
}


.toolPanel>.items>i {
    color: grey;
}

.basicComponent {
    border: 2px solid rgba(0, 0, 0, 0);
    position: relative;
    transition: $transition-0-1;
    &.editMode{
        cursor: pointer;
        &:hover {
            border: 2px solid colorRange($black, 1);
    
            >.ribbon {
                display: flex;
            }
    
            >.stretch- {
    
                &width,
                &height,
                &size {
                    display: inline-block;
                }
            }
        }
    }


    &.active {
        border: 2px solid $warning-0;

        >.ribbon {
            background-color: $warning-0;
            display: flex;
            color: white;
        }

        >.stretch- {

            &width,
            &height,
            &size {
                display: inline-block;
                background-color: $warning-0;
            }
        }
    }

    >.ribbon {
        position: absolute;
        top: 0;
        right: -2px;
        transform: translateY(-100%);
        background-color: $light;
        padding: 5px 10px;
        display: none;
        font-size: 12px;
        @include rounded($radius-2, "t");
        @include rounded($radius-2, "r");

        i {
            padding: 0 10px;

            &:hover {
                color: $danger-0;
            }
        }
        >div{
            margin: -5px -10px -5px 0;
        }
    }

    .stretch- {

        &width,
        &height, 
        &size {
            position: absolute;
            z-index: 1;
            padding: 0;
            line-height: 15px;
            font-size: 10px;
            width: 15px;
            color: $white;
            border: 0px;
            background-color: $dark;
            @include rounded($radius-1, "xy");
            display: none;
        }

        &width {
            top: 50%;
            right: 0%;
            cursor: e-resize;
            transform: translate(50%, -50%);
        }

        &height {
            left: 50%;
            bottom: 0%;
            cursor: n-resize;
            transform: translate(-50%, 50%);
        }

        &size {
            bottom: 0%;
            right: 0%;
            cursor: nw-resize;
            transform: translate(50%, 50%) rotate(-45deg);
        }
    }

    &.dragEffect {
        @include animation("dragEffect 3s infinite");

        @include keyframes("dragEffect") {

            0%,
            100% {
                border: 2px solid $primary-1;
            }
            
            50% {
                border: $lightborder-2;
            }
        }
    }

    &.replaceEffect {
        @include animation("replaceEffect 1s infinite");

        @include keyframes("replaceEffect") {

            0%,
            100% {
                transform: translateY(0px);
            }

            50% {
                transform: translateY(10px);
            }
        }
    }
}

.overviewHeading {
    color: $light;
    @include xyProps("border", "xy", "2px solid " + $light);
    @include rounded($radius-4, "xy");
    padding: 4px 0 4px 10px;
    overflow: hidden;

    &:hover {
        color: $dark;
        @include xyProps("border", "xy", "2px solid " + $dark);
    }

    &:focus .overviewHeading {
        color: $dark;
        @include xyProps("border", "xy", "2px solid " + $dark);
    }

    >i {
        font-size: 15px;
        padding: 5px;
    }

    >input {
        border: $lightborder-1;
        outline: unset;
        padding-left: 10px;
        font-weight: bold;
    }
}




.propertiesLabels {
    @include split("b");
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    color: $dark;
}

.setAlignStr0 {
    stroke: black;
    stroke-width: 0.65;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 2.61313;
}

.setAlign0 {
    fill: none;
}

.setAlign1 {
    fill: black;
}

.setAlign2 {
    fill: #999999;
    cursor: pointer;

    &:hover {
        stroke: #999999;
        stroke-width: 5;
    }

    &.active {
        stroke: black;
        stroke-width: 10;
    }
}

.selectiveColors {
    width: 30px;
    height: 30px;
    @include rounded($radius-1, "xy");
    cursor: pointer;

    &:hover {
        @include xyProps("border", "xy", "2px solid " + $light);
    }
}